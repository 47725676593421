@use '../styles/colors';
@use '../styles/theme';
@use '../styles/layout';
@use '../styles/mixins';

.ctaCentered {
  margin-top: 2rem;
  text-align: center;

  .solidButton {
    color: colors.$brand-secondary !important;
  }
}

.messageBody {
  margin-bottom: 50px;

  p {
    color: theme.$body-color;
    font: normal normal 400 20px/27px 'SecondaryFontFamily';
    text-decoration: none;
  }
}
