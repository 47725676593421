@use './colors.scss';

$body-color: colors.$text-primary;
$body-background-color: colors.$brand-primary;
$body-font-family: 'SecondaryFontFamily';
$navbar-background-color: colors.$brand-primary;
$navbar-link-color: colors.$brand-secondary;
$navbar-active-link-color: colors.$accent-4;
$navbar-active-link-list-color: colors.$brand-secondary;
$navbar-button-background-color: colors.$brand-primary;
$navbar-button-border-color: colors.$brand-primary;
$navbar-button-text-color: colors.$white;
$hero-title-color: colors.$accent-5;
$hero-subtitle-color: colors.$accent-4;
$hero-cta-background-color: colors.$brand-primary;
$hero-cta-text-color: colors.$brand-secondary;
$hero-background-color: colors.$accent-3;
$message-title-color: colors.$brand-secondary;
$message-subtitle-color: colors.$brand-secondary;
$message-body-color: colors.$brand-secondary;
$item-grid-title-color: colors.$brand-secondary;
$item-grid-body-color: colors.$brand-secondary;
$primary-button-background-color: colors.$brand-primary;
$primary-button-text-color: colors.$white;
$footer-background-color: colors.$accent-1;
$footer-title-text-color: #1e2550;
$footer-body-text-color: #1e2550;
$footer-copyright-background-color: colors.$brand-primary;
$footer-copyright-text-color: #1e2550;

:export {
  body-color: $body-color;
  body-background-color: $body-background-color;
  body-font-family: $body-font-family;
  navbar-background-color: $navbar-background-color;
  navbar-link-color: $navbar-link-color;
  navbar-active-link-color: $navbar-active-link-color;
  navbar-button-background-color: $navbar-button-background-color;
  navbar-button-border-color: $navbar-button-border-color;
  navbar-button-text-color: $navbar-button-text-color;
  hero-title-color: $hero-title-color;
  hero-subtitle-color: $hero-subtitle-color;
  hero-cta-background-color: $hero-cta-background-color;
  hero-cta-text-color: $hero-cta-text-color;
  hero-background-color: $hero-background-color;
  message-title-color: $message-title-color;
  message-subtitle-color: $message-subtitle-color;
  message-body-color: $message-body-color;
  item-grid-title-color: $item-grid-title-color;
  item-grid-body-color: $item-grid-body-color;
  primary-button-background-color: $primary-button-background-color;
  primary-button-text-color: $primary-button-text-color;
  footer-background-color: $footer-background-color;
  footer-title-text-color: $footer-title-text-color;
  footer-body-text-color: $footer-body-text-color;
  footer-copyright-background-color: $footer-copyright-background-color;
  footer-copyright-text-color: $footer-copyright-text-color;
}

@import '~bootstrap/scss/bootstrap';
