$black: #000;
$greyDarker: #0f0f0f;
$greyDark: #1c1c1c;
$grey: #707070;
$greyMid: #b8b8b8;
$greyLight: #c9c9c9;
$greyLight2: #f8f9fa;
$greyLighter: #dedede;
$greyLighter2: #ecedee;
$white: #fff;
$red: #be2230;
$redMid: #ff4a60;
$redLight: #ff4a60;

$brand-primary: #f5e3dd;
$brand-secondary: #1f2550;
$brand-tertiary: #e4ae61;
$accent-1: #ebc6ba;
$accent-2: #cce8e7;
$accent-3: #f1eade;
$accent-4: #faf7f2;
$accent-5: #ffffff;
$text-primary: #5a5a5a;
$text-secondary: #939598;
$text-tertiary: #939598;

:export {
  black: $black;
  greyDarker: $greyDarker;
  grey: $grey;
  greyMid: $greyMid;
  greyLight: $greyLight;
  greyLighter: $greyLighter;
  white: $white;
  red: $red;
  redMid: $redMid;
  redLight: $redLight;
  brandPrimary: $brand-primary;
  brandSecondary: $brand-secondary;
  brandTertiary: $brand-tertiary;
  accent1: $accent-1;
  accent2: $accent-2;
  accent3: $accent-3;
  accent4: $accent-4;
  accent5: $accent-5;
  textPrimary: $text-primary;
  textSecondary: $text-secondary;
  textTertiary: $text-tertiary;
}
