@use '../../styles/colors';
@use '../../styles/layout';
@use '../../styles/mixins';

.countdown {
  background-color: colors.$white;

  &.fill {
    display: flex;
    align-items: center;
    min-height: 100vh;
  }

  .countdownContent {
    display: flex;
    margin: auto;

    @media screen and (max-width: layout.$md) {
      flex-direction: column;
    }

    @media screen and (min-width: layout.$md) {
      min-height: 500px;
    }

    .itemGrid {
      display: flex;
      flex: 0 0 50%;
      flex-direction: column;
      justify-content: center;
    }

    .copy {
      display: flex;
      flex: 0 0 50%;
      flex-direction: column;
      justify-content: center;
      padding: 5rem;
      color: colors.$text-secondary;

      @media screen and (max-width: layout.$lg) {
        padding: 3rem;
      }

      .index {
        color: colors.$red;
        font: normal normal bold 144px/188px 'SecondaryFontFamily';
      }

      h1 {
        font: normal normal bold 44px/57px 'PrimaryFontFamily';
        color: colors.$brand-secondary;
        margin-bottom: 25px;
        // word-break: break-all;
      }

      h3 {
        font: normal normal 20px/27px 'SecondaryFontFamily';
        color: colors.$brand-secondary;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      button {
        @include mixins.primaryButton();
      }

      .ctaLink {
        flex-grow: 0;
      }

      .content {
        p {
          font: normal normal 300 20px/32px 'SecondaryFontFamily';
          color: colors.$brand-secondary;
        }

        button {
          @include mixins.primaryButton();
        }
      }
    }

    .image {
      display: flex;
      flex: 1 1 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  @media screen and (min-width: layout.$md) {
    .imageLeft {
      flex-direction: row-reverse;
    }
  }
}
