@use '../styles/colors';
@use '../styles/layout';
@use '../styles/mixins';

.ctaCentered {
  margin-top: 2rem;
  text-align: center;
}

.item {
  margin: 30px 0;

  .titleText {
    font: normal normal 20px/25px 'SecondarySemiBoldFontFamily';
    color: colors.$brand-secondary;
    margin-bottom: 5px;
  }

  .bodyText {
    font: normal normal 300 16px/20px 'SecondaryFontFamily';
    color: colors.$brand-secondary;
    margin-bottom: 0;
  }

  .itemLink {
    text-decoration: none;
  }
}

.googleMapWrapper {
  min-height: 500px;
  width: 100%;

  @media screen and (max-width: layout.$md) {
    height: 500px;
  }

  .googleMapResponsiveWrapper {
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    height: 0;

    @media screen and (max-width: layout.$md) {
      padding-bottom: 0;
    }

    iframe {
      left: 0;
      top: 0;
      height: 100% !important;
      width: 100% !important;
      position: absolute;
    }
  }
}
